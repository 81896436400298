/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
@mixin button-disabled {
    pointer-events: none;
    opacity: .25;
}

@mixin button {
    align-items: center;
    background-color: $primary-color-1;
    border-radius: 4px;
    cursor: pointer;
    display: inline-flex;
    color: white;
    font-size: 16px;
    font-weight: 700;
    justify-content: center;
    height: 48px;
    min-height: 48px;
    line-height: 20px;
    text-decoration: none;
    text-transform: uppercase;
    transition-duration: .25s;
    padding-inline: 40px;
    transition-timing-function: ease-out;
    transition-property: background-color, color, border;
    will-change: background-color, color, border;

    @include mobile {
        user-select: none;
        width: 100%;
    }

    &:hover,
    &:focus {
        background-color: $primary-color-2
    }

    &[disabled]{
        background-color: $btn-color1;
        cursor: not-allowed;
    }

    &_isHollow {
        background-color: transparent;
        text-transform: uppercase;
        border: 2px solid $primary-color-1;
        border-radius: 4px;
        color: $primary-color-1;
        height: 44px;
        text-decoration: none;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;

        &:not([disabled]) {
            &:hover {
                @include desktop {
                    cursor: pointer;
                    color: white;
                    background-color: $primary-color-1;
                }
            }
        }

        &[disabled]{
            border-color: $btn-color1;
            color: $white;
            cursor: not-allowed;
        }    
    }

    &_isWithoutBorder {
        background-color: transparent;
        text-transform: uppercase;
        border: 0;
        color: $primary-color-1;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;

        &:not([disabled]) {
            &:hover {
                @include desktop {
                    cursor: pointer;
                    background-color: transparent;
                    color: $extra-color-1;
                }
            }
        }

        &[disabled]{
            color: $white;
            cursor: not-allowed;
        }    
    }

    &_likeLink {
        background-color: transparent;
        border: none;
        text-transform: none;
        margin: 0;
        padding: 0;
        text-align: start;
        color: $extra-color-1;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;

        &:active {
            text-decoration: underline;
            padding: 0;
        }

        @include hoverable {
            &:hover,
            &:focus {
                background-color: transparent;
                text-decoration: underline;
                padding: 0;
                color: $primary-color-1;
                cursor: pointer;
            }
        }

        &[disabled]{
            color: $btn-color1;
            cursor: not-allowed;
        }    
    }
}
