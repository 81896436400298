/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.CartSelectShippingPopup {
    .Popup {
        &-Content {
            max-width: 498px;
            padding: 24px 20px;

            &::after {
                display: none;
            }
        }
    }

    &-Carrier {
        align-items: center;
        border: 1px solid $neutral-color-5;
        border-radius: 4px;
        background-color: $neutral-color-8;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 0;
        padding: 9px 16px;

        &::before {
            display: none;
        }

        &:last-child {
            margin-bottom: 0;
        }

        button {
            align-items: center;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            font-weight: 600;
            width: 100%;

            &>* {
                color: $font-color-1;
            }

            h4 {
                font-size: 14px;
            }

            span {
                font-size: 16px;
            }

            img {
                margin-right: 10px;
                max-height: 80px;
                max-width: 100%;
                width: unset;
            }

            @include mobile {
                flex-direction: row;

                h4 {
                    font-size: 14px;
                    text-align: center;
                }

                span {
                    margin-left: auto;
                }

                img {
                    margin-bottom: 0;
                    margin-right: 6px;
                }
            }
        }

        &Price {
            font-size: 16px;
        }

        &Title {
            font-size: 14px;

            @include mobile {
                font-size: 16px
            }
        }

        &_isSelected {
            border: 2px solid #ff3b30;
            padding: 8px 15px;
        }

        @include mobile {
            &:hover,
            &:active,
            &:focus {
                border-color: #ff3b30;
                border-width: 2px;
                padding: 8px 15px;
            }
        }
    }

    &-Carriers {
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(2, 1fr);
        margin: 0;

        @include mobile {
            grid-template-columns: 1fr;
        }
    }

    &-DeliveryLockerSelect {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 25px;

        h5 {
            font-size: 20px;
            font-weight: 600;
            margin: 0;
            line-height: 26px;
        }

        .Field {
            margin-top: 0;

            &-Label {
                font-size: 14px;
                font-weight: 600;

                @include mobile {
                    font-size: 16px
                }
            }
        }
    }

    &-List {
        width: 100%;

        &_toSelect {
            border-color: $secondary-color-1;
        }
    }

    &-OrlenPoint {
        border: 1px $neutral-color-11 solid;
        border-radius: 4px;
        cursor: pointer;
        font-size: 16px;
        padding: 8px;

        &:hover {
            border-color: $primary-color-1;
        }

        &::before {
            display: none;
        }

        &_isSelected {
            border-color: $primary-color-1;
            border-width: 2px;
            padding: 7px;
        }

        button {
            cursor: pointer;
            font-size: 16px;
            text-align: left;
            width: 100%;
        }
    }

    &-OrlenPointsList {
        margin-top: 4px;
        max-height: 160px;
        overflow: hidden;
        overflow-y: auto;
    }

    &-SaveButton {
        cursor: pointer;
        margin-top: 20px;
        width: 100%;
    }

    &-Title {
        font-size: 24px;
        font-weight: 600;
        line-height: 30px;
        margin-bottom: 20px;
        margin-top: -4px;
    }

    &-ZipCodeField {

        input {
            width: 100%;
        }
    }
}
