/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.CartCoupon {
    @include mobile {
        width: 100%;
        padding: 0 0 16px;
    }

    @include tablet-portrait {
        width: 100%;
    }

    &-Message {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
    }

    &-MessageText {
        margin: 0;
        word-break: break-word;
        margin-inline-end: 24px;
        display: inline-block;
        font-size: 16px;
    }

    .Field {
        margin-block-start: 0;
    }

    .Form {
        margin-block-start: 12px;
        display: inline-flex;
        width: 100%;
    }

    input, button {
        vertical-align: top;
    }

    &-Input {
        display: inline-block;
        margin-right: 0;
        flex: 1 1 100%;

        input {
            width: 100%;

            @include mobile {
                width: 100%;
            }

            @include tablet-portrait {
                width: 100%;
            }
        }

        @include mobile {
            width: 100%;
            margin-block-start: 0;
        }

        @include tablet-portrait {
            width: 100%;
            margin-block-start: 0;
        }
    }

    &-Button {
        @include button;

        &_isRemoveButton {
            padding-inline: 30px;

            svg {
                margin-right: 4px;
                path {
                    stroke: $primary-color-1;
                }
            }
    
            @include hoverable {
                &:hover {
                    svg {
                        path {
                            stroke: $white;
                            transition: ease-out .25s;
                        }
                    }
                }
            }
        }


        height: auto !important;

        @include mobile {
            width: 100%;
            margin-inline-start: 0;
            padding-inline: 20px;
            max-width: 110px;
            font-size: 14px;
        }

        @include tablet-portrait {
            margin-inline-start: 0;
        }
    }

    &-Title {
        font-size: 14px;
        font-weight: normal;
        text-transform: uppercase;
        margin: 0;
        margin-block-end: 20px;
        @include mobile {
            font-size: 16px
        }
    }
}
