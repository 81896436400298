/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --primary-base-color: #ff0057;
}

.CartItem {
    @include mobile {
        border-bottom: 1px solid $extra-color-6;

        .CartItem {
            &-Qty {
                input {
                    font-size: 16px;
                    font-weight: 600;
                    min-width: 56px;
                }
            }
        }
    }

    @include desktop {
        &:first-of-type {
            border-top: 1px solid $neutral-color-6;
        }

        &-CartItemRows {
            justify-content: flex-start;
        }
    }

    &-Wrapper {
        &_isSummary {
            @include desktop {
                background-color: $neutral-color-7;

                .CartItem-Heading {
                    font-size: 14px;

                    @include mobile {
                        font-size: 16px
                    }
                }

                .CartItem-Price {
                    font-size: 18px;
                }
            }
        }

        &_isMobileLayout {
            min-height: auto;
        }
    }

    &-Stock {
        align-items: center;
        background: $red-color-1;
        border-radius: 24px;
        display: flex;
        font-size: 14px;
        font-weight: 600;
        min-height: 24px;
        padding: 0 8px 0 24px;
        max-width: fit-content;

        @include mobile {
            font-size: 14px;
            margin-bottom: 5px;
        }

        &::before {
            background: $red-color-2;
            border-radius: 10px;
            content: '';
            height: 10px;
            left: 8px;
            position: absolute;
            width: 10px;
        }

        &_status {
            background: $green-color-1;

            &::before {
                background: $green-color-2;
            }

            &_announcements,
            &_presale,
            &_to_order {
                background: $orange-color-1;

                &::before {
                    background: $orange-color-2;
                }
            }
        }

        &_onlyBookstore {
            background: $orange-color-1;

            &::before {
                background: $orange-color-2;
            }
        }
    }

    .CartItem-OutOfStockStatus {
        width: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        font-size: 14px;
        line-height: 20px;
        padding: 6px 20px 6px 22px;
        border: 1px solid $yellow-status-color;
        margin-bottom: 6px;
        font-weight: 400;
        border-radius: 2px;

        div {
            margin-left: 12px;
        }

        &:last-child {
            margin-bottom: 16px;
        }

        &_isNotEnoughProduct {
            border-color: $red-color-3;
            background-color: $red-color-4;

            b {
                margin-inline: 4px;
            }

            svg {
                path {
                    stroke: $extra-color-10;
                }
            }
        }
    }

    &-DiscountBadge {
        margin-block-start: auto;

        span {
            background: $secondary-color-1;
            padding: 2px 6px;
            font-weight: 700;
            font-size: 13px;
            border-radius: 2px;

            @include mobile {
                font-size: 16px;
            }
        }

        @include mobile {
            height: 21px;
        }
    }

    &-Title {
        justify-content: start !important;

        @include mobile {
            margin-block-end: 0;
        }
    }

    &-Heading {
        color: $font-color-1;
        overflow: hidden;
        line-height: 20px;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 400;

        @include mobile {
            font-size: 16px;
        }

        @include desktop {
            font-size: 16px;
        }
    }

    &-ProductActions {
        gap: 12px;
    }

    &-Delete {
        cursor: pointer;
        color: $extra-color-1;

        path {
            stroke: $extra-color-1;
        }

        &:hover {
            color: $primary-color-1;

            .CloseIcon {
                fill: $primary-color-1;
            }

            @include desktop {
                path {
                    stroke: $primary-color-1;
                }
            }
        }

        @include mobile {
            height: 24px;

            @media (hover: hover) {
                path {
                    stroke: $primary-color-1;
                }
            }
        }

        &:active {
            path {
                stroke: $primary-color-1
            }
        }
    }

    &-Discount {
        color: $font-color-2;
        font-size: 14px;
        text-decoration: line-through;

        @include mobile {
            font-size: 16px
        }
    }

    &-Price {
        color: $font-color-1;
        font-size: 24px;
        font-weight: 600;
        margin-top: 2px;

        &_isDiscounted {
            color: $primary-color-1;
        }

        @include mobile {
            font-size: 18px;
        }
    }

    &-PriceWrapper {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
    }

    &-Options {
        display: none;
    }

    & &-Qty {
        // hack to obtain required specificity
        margin-block-start: 0;
        display: flex;
        cursor: default;

        @include mobile {
            align-items: center;
            font-size: 16px;
        }

        input {
            width: 0 !important;

            @include mobile {
                width: 56px;
                min-width: 32px;
                background-color: transparent;
            }
        }

        button {
            &:not([disabled]) {
                cursor: pointer;
            }
        }
    }

    .CartItem-QuantityWrapper {

        .Field-ErrorMessage {
            display: none;
        }

        &_isCartOverlay {
            & button {
                border-color: $extra-color-1;
                border-radius: 4px;
                width: 36px;
                height: 36px;

                svg {
                    fill: $extra-color-1;
                }

                &:hover {
                    border-color: $primary-color-1;

                    svg {
                        fill: $primary-color-1;
                    }
                }

                &:disabled {
                    border-color: $disabled-color;
                    opacity: 1;

                    svg {
                        fill: $disabled-color;
                    }
                }
            }

            .CartItem-Qty {
                font-size: 14px;
                position: relative;

                @include mobile {
                    font-size: 16px;
                }

                input {
                    font-size: 16px;
                    font-weight: 600;
                    min-width: 56px !important;
                }
            }
        }

        &_isOutOfStock {
            max-width: 124px;
        }
    }

    &-Quantity {
        font-size: 14px;

        @include mobile {
            margin-block: 4px 0px;
            font-size: 16px
        }
    }
}