/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

$white: #fff;
$black: #000;
$default-primary-base-color: #f26323;
$default-primary-dark-color: darken($default-primary-base-color, 15%);
$default-primary-light-color: lighten($default-primary-base-color, 15%);
$default-secondary-base-color: #eee;
$default-secondary-dark-color: darken($default-secondary-base-color, 15%);
$default-secondary-light-color: lighten($default-secondary-base-color, 15%);
$font-open-sans: "Open Sans", sans-serif;
$font-standard-size: 16px;

$font-color-1: #383838;
$font-color-2: #626262;
$font-color-3: #989898;
$font-color-4: #252525;

$success-color: #2fbf71;
$availability-color: #36B587;
$light-green: #EBFAF4;
$yellow-status-color: #fab100;

$error-color: #f23d3d;

$disabled-color: #cbcbcb;

$btn-color1: #cbcbcb;

$primary-color-1: #ff0057;
$primary-color-2: #cc0046;
$primary-color-3: #ff3379;
$primary-color-4: #ff669a;
$primary-color-5: #ff99bc;
$primary-color-6: #ffccdd;
$primary-color-7: #FF0556;
$primary-color-8: #DC0048;

$secondary-color-1: #fab100;
$secondary-color-2: #c79314;
$secondary-color-3: #fabf32;
$secondary-color-4: #face64;
$secondary-color-5: #fadd96;
$secondary-color-6: #faebc8;
$secondary-color-7: #FFC13F;
$secondary-color-8: #FFB10D;

$extra-color-1: #0a1e58;
$extra-color-2: #040d26;
$extra-color-3: #2b448c;
$extra-color-4: #617abf;
$extra-color-5: #acbef2;
$extra-color-6: #e6ebfc;
$extra-color-7: #F7F9FF;
$extra-color-8: #d8f0c0;
$extra-color-9: #36B587;
$extra-color-10: #ed1c24;
$extra-color-11: #001B54;
$extra-color-12: #0a31a5;

$neutral-color-1: #040d26;
$neutral-color-2: #202533;
$neutral-color-3: #4e5362;
$neutral-color-4: #7a8298;
$neutral-color-5: #b8bdcc;
$neutral-color-6: #d3d8e6;
$neutral-color-7: #f7f9ff;
$neutral-color-8: #f9f9f9;
$neutral-color-9: #f6f6f6;
$neutral-color-10: #d9d9d9;
$neutral-color-11: #ebf4f4;
$neutral-color-12: #fffafc;
$neutral-color-13: #f0f0f0;
$neutral-color-14: #c4c4c4;
$neutral-color-15: #A8B0BD;
$neutral-color-16: #444444;
$neutral-color-17: #D3D8E5;

//statuses
$green-color-1: #ebfaf4;
$green-color-2: #36b587;
$green-color-3: #A6C316;
$green-color-4: #F3FFBA;
$orange-color-1: #faf6eb;
$orange-color-2: $secondary-color-1;
$red-color-1: #fff0f0;
$red-color-2: #ed1c24;
$red-color-3: #F00C0C;
$red-color-4: #FAEBEB;
