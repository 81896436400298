/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    --cart-overlay-totals-background: var(--secondary-base-color);
    --cart-overlay-divider-background: #d8d8d8;
    --cart-overlay-promo-background: #fff;
}

.CartOverlay {
    cursor: auto;
    border: 0;
    border-radius: 4px 0 4px 4px;
    filter: drop-shadow(2px 2px 30px rgba(0, 0, 0, 0.25));
    right: -100px !important;
    width: 460px;

    @include desktop {
        position: absolute;
        inset-block-start: calc(var(--header-nav-height) - 7px);
        inset-inline-end: -10px;
        padding: 0;
        width: 460px;
    }

    &::before {
        @include desktop {
            content: '';
            position: absolute;
            inset-block-start: -8px;
            inset-inline-end: 6px;
            height: 28px;
            width: 28px;
            top: -14px;
            transform: rotate(45deg);
            background-color: white;
            border-inline-start-color: white;
            border-block-start-color: white;
        }
    }

    &-Additional {
        border-radius: 4px 4px 0 0;
        box-shadow: 0px -2px 12px rgba(0, 0, 0, 0.13);
        font-weight: 400;
    }

    &-Empty {
        padding: 16px;
        text-align: center;
    }

    &-Actions {
        display: flex;
        padding: 16px;

        @include mobile {
            padding: 14px;
        }
    }

    &-ContentHeader {
        align-items: center;
        border-bottom: 1px $neutral-color-10 solid;
        display: flex;
        font-size: 20px;
        font-weight: 600;
        justify-content: space-between;
        margin: 12px 16px 0px;
        padding-bottom: 12px;

        div:nth-child(2) {
            display: flex;
            font-size: 16px;
            font-weight: 400;
            gap: 4px;

            span {
                font-weight: 600;
            }
        }
    }

    &-OutOfStockProductsWarning {
        padding: 10px;
        display: flex;
        justify-content: center;
        background-color: var(--primary-error-color, red);
        color: var(--color-white);
        font-size: 14px;
        font-weight: 600;

        @include mobile {
            font-size: 16px
        }
    }

    &-CheckoutButton {
        margin-inline-start: 12px;

        @include mobile {
            margin-inline-start: 16px;
            width: 100%;
            display: block;
            text-align: center;
        }

        @include desktop {
            flex-grow: 1;
            text-align: center;
        }
    }

    &-CartButton {
        height: 48px;
        padding: 0 24px;
        width: 100%;

        @include mobile {
            display: none;
        }
    }

    &-Promo,
    &-Total {
        font-weight: 600;

        @include mobile {
            min-height: 56px;
        }

        @include desktop {
            min-height: 36px;
        }
    }

    &-Promo {
        padding: 12px;
        text-align: center;
        background: var(--cart-overlay-promo-background);
        margin-block-end: 0;

        @include mobile {
            padding: 14px;
            border-block-end: 1px solid var(--cart-overlay-divider-background);
            border-block-start: 1px solid var(--expandable-content-color);
        }

        @include desktop {
            padding: 10px;
            color: var(--color-white);
            background: var(--primary-base-color);
        }

        strong {
            margin: 0 5px;
        }
    }

    &-Items {
        padding-inline: 16px;
        max-height: 40vh;
        overflow-y: scroll;
        left: 0;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &-Tax {
        font-size: 14px;

        @include mobile {
            font-size: 16px
        }
    }

    &-Total,
    &-Tax,
    &-Discount {
        word-wrap: break-word;
        display: flex;
        justify-content: space-between;
        padding-block-start: 12px;
        padding-block-end: 6px;
        padding-inline: 16px;
        background: none;

        @include mobile {
            padding-block-start: 14px;
            padding-block-end: 7px;
            padding-inline: 14px;
        }

        dd {
            text-align: end;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            white-space: nowrap;

            span {
                display: block;
                font-size: 12px;
                font-weight: 400;
            }
        }
    }

    &-Discount {
        border-bottom: 1px $extra-color-6 solid;
        color: $primary-color-1;
        font-size: 16px;
        font-weight: 400;
        margin: 0 16px;
        padding-bottom: 12px;
        padding-left: 0;
        padding-right: 0;
    }

    &-FreeShipping {
        align-items: center;
        background-color: $extra-color-6;
        border-radius: 4px;
        color: $extra-color-3;
        display: flex;
        font-size: 16px;
        font-weight: 400;
        gap: 10px;
        justify-content: center;
        margin: 16px;
        padding: 10px;

        div {
            align-items: center;
            display: flex;
            gap: 5px;
        }

        span {
            color: $extra-color-3;
            font-size: 18px;
            font-weight: 600;
        }

        svg {
            height: 24px;
            width: 24px;
        }

        &_reached {
            background-color: $extra-color-8;
            color: $font-color-1;
        }
    }

    &-DiscountCoupon {
        word-break: break-word;
    }

    &-Total {
        font-weight: bold;
        font-size: 18px;

        dd {
            font-size: 24px;
            font-weight: 600;
        }

        dt {
            font-size: 16px;
            font-weight: 400;
        }
    }

    &-TotalGross {
        display: flex;
        font-size: 16px;
        font-weight: 400;
        justify-content: space-between;
        padding: 16px 16px 0;
    }

    &-ContentWrapper {
        border: 0;
        overflow: hidden;
    }

    .CartItem {
        &:first-of-type {
            border-top: 0 solid transparent;
        }

        &:last-of-type {
            border-bottom: 0 solid transparent;
        }

        &-Heading {
            font-size: 16px;
            font-weight: 400;
        }

        &-Price {
            font-size: 18px;
        }

        &-Qty {
            height: 36px;
            z-index: 1;

            input {
                color: $font-color-1;
                font-size: 16px;
                font-weight: 600;
            }
        }

        &-Stock {
            margin-bottom: 12px;
        }
    }
}