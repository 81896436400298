/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.CartSelectShipping {
    border: 1px solid $neutral-color-6;
    border-radius: 6px;
    margin-bottom: 20px;
    padding: 10px 16px 16px;

    @include mobile {
        border: none;
        margin-bottom: 68px;
        padding: 20px 0;
    }

    &-Details {
        &_isCourier {
            color: $font-color-2;
        }
    }

    &-SelectMethodWrapper {
        @include mobile {
            display: flex;
            flex-direction: column;
        }
    }

    &-InnerMethodWrapper {
        @include mobile {
            display: flex;
            align-items: center;

            svg {
                margin-right: 10px;
            }
        }
    }

    &-Header {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 25px;
    }

    &-Price {
        font-style: normal;
    }

    &-SelectedIcon {
        position: absolute;
        right: 0;
        transform: translate(30%, -30%);
        top: 0;

        path {
            fill: white;
            stroke: $extra-color-1;
        }
    }

    &-SelectedMethod {
        border-left: 2px solid $neutral-color-6;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        line-height: 20px;
        margin-top: 20px;
        padding-left: 8px;

        @include mobile {
            font-size: 16px;
            margin-top: 10px;
        }

        b {
            font-weight: 600;
        }
    }

    &-ShippingType {
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(2, 1fr);

        @include mobile {
            display: flex;
            flex-direction: column;
            min-height: 48px;
            gap: 12px;
        }

        &Item {
            min-height: 100%;

            &::before {
                display: none;
            }

            button {
                align-items: center;
                border: 1px solid $neutral-color-5;
                border-radius: 4px;
                column-gap: 10px;
                cursor: pointer;
                display: grid;
                grid-template-areas:
                    "icon title"
                    "icon price";
                grid-template-columns: 28px 1fr;
                font-size: 14px;
                font-weight: 600;
                line-height: 18px;
                min-height: 100%;
                padding: 10px 12px;
                width: 100%;

                @include mobile {
                    font-size: 16px;
                    display: block;
                }

                &:disabled {
                    border: 1px solid $disabled-color;
                    cursor: auto;

                    path {
                        stroke: $disabled-color;
                    }

                    span {
                        color: $disabled-color;
                    }
                }

                span {
                    color: $font-color-2;
                    line-height: 12px;
                    text-align: left;

                    &:nth-of-type(1) {
                        grid-area: title;

                        @include mobile {
                            flex: 1;
                            line-height: 20px;
                        }
                    }

                    &:nth-of-type(2) {
                        font-size: 12px;
                        font-weight: 400;
                        grid-area: price;
                    }
                }

                svg {
                    grid-area: icon;
                    height: 28px;
                    width: 28px;
                }
            }

            &_isSelected {
                button {
                    background-color: $extra-color-7;
                    border: 2px solid $extra-color-1;
                    padding: 10px 10px 10px 12px;

                    span {
                        color: $font-color-1;
                    }

                    svg {
                        path {
                            stroke: $font-color-1;
                        }
                    }
                }
            }
        }
    }
}